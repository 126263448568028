<script>
import TransferSummary from "@/components/transfer/transfer-summary";

export default {
  name: "summaryDataStep",
  props: {
    summaryData: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: { TransferSummary },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <TransferSummary :summaryData="summaryData" />
</template>
