<script>
import {
  required,
  maxLength,
  minLength,
  numeric,
  requiredIf,
  helpers,
} from "vuelidate/lib/validators";

export default {
  name: "inputTaxNumber",
  props: {
    incomingValue: {
      type: String,
      required: false,
    },
    disable: {
      type: Number,
      required: false,
    },
    withoutTaxNumber: {
      type: Boolean,
      required: false,
    },
    inputLabel: {
      type: String,
      required: false,
      default: "NIP",
    },
  },
  data() {
    return {
      disabledSwitch: 0,
      taxNumber: "",
      whitoutTax: false,
    };
  },
  validations: {
    taxNumber: {
      required: requiredIf(function (value) {
        if (this.whitoutTax) {
          return false;
        }
        return true;
      }),
      maxLength: maxLength(10),
      minLength: minLength(10),
      numeric,
      validTaxNumber: (value) => {
        if (value) {
          let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
          value = value.replace(/[\s-]/g, "");

          if (value.length === 10 && parseInt(value, 10) > 0) {
            let sum = 0;
            for (let i = 0; i < 9; i++) {
              sum += value[i] * weights[i];
            }
            return sum % 11 === Number(value[9]);
          } else if (!helpers.req(value)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true
        }
      },
    },
  },
  created() {
    if (this.incomingValue) {
      this.taxNumber = this.incomingValue;
    }
    if (this.disable) {
      this.disabledSwitch = this.disable;
    }
    if (this.withoutTaxNumber) {
      this.whitoutTax = this.withoutTaxNumber;
    }
  },
  watch: {
    incomingValue: function () {
      this.taxNumber = this.incomingValue;
    },
    disable: function () {
      this.disabledSwitch = this.disable;
    },
    withoutTaxNumber: function () {
      this.whitoutTax = this.withoutTaxNumber;
      // if (this.whitoutTax) {
      //   this.taxNumber = "";
      // }
    },
    taxNumber: function () {
      this.$emit("input-tax-number", this.taxNumber);
    },
  },
};
</script>

<template>
  <div class="form-group">
    <label for="taxNumber">
      {{ inputLabel }}
      <em class="text-danger" v-if="!whitoutTax">*</em>
    </label>
    <!-- <input id="taxNumber" v-model.trim="taxNumber" @input="$v.taxNumber.$touch()" v-mask="'##########'" class="form-control" :class="{ 'is-invalid': $v.taxNumber.$error, 'is-valid': !$v.taxNumber.$invalid && !$v.taxNumber.$error }" type="text" name="taxNumber" :placeholder="'Wpisz ' + inputLabel" value required :disabled="disabledSwitch == 0 || whitoutTax" /> -->
    <input id="taxNumber" v-model.trim="taxNumber" @input="$v.taxNumber.$touch()" v-mask="'##########'" class="form-control" :class="{ 'is-invalid': $v.taxNumber.$error, 'is-valid': !$v.taxNumber.$invalid && !$v.taxNumber.$error }" type="text" name="taxNumber" :placeholder="'Wpisz ' + inputLabel" value required :disabled="disabledSwitch == 0" />
    <div v-if="$v.taxNumber.$error" class="invalid-feedback">
      <span class="d-block" v-if="$v.taxNumber.$error && !$v.taxNumber.required">Pole jest wymagane!</span>
      <span class="d-block" v-if="$v.taxNumber.$error && !$v.taxNumber.maxLength">Przekroczona dozwolona ilość znaków!</span>
      <span class="d-block" v-if="$v.taxNumber.$error && !$v.taxNumber.minLength">Zbyt mała ilość znaków!</span>
      <span class="d-block" v-if="$v.taxNumber.$error && !$v.taxNumber.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
      <span class="d-block" v-if="$v.taxNumber.$error && !$v.taxNumber.validTaxNumber">Niepoprawny numer nip!</span>
    </div>
  </div>
</template>
