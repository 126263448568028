<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { authComputed } from "@/state/helpers";
import { roleService } from "@/app/service/roleService";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import taxApplicantDataStep from "./steps/tax-applicant-data-step";
import taxCompanyDataStep from "./steps/tax-company-data-step";
import taxProviderDataStep from "./steps/tax-provider-data-step";
import taxTransferDataStep from "./steps/tax-transfer-data-step";
import taxSummaryDataStep from "./steps/tax-summary-data-step";
import TransferInfoSidebar from "@/components/transfer/transfer-info-sidebar";
import Vue from "vue";
import Repository from "@/app/repository/repository-factory";
import moment from "moment";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);

/**
 * Przelew skarbowy component
 */
export default {
  page: {
    title: "Przelew skarbowy",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    FormWizard,
    TabContent,
    taxApplicantDataStep,
    taxCompanyDataStep,
    taxProviderDataStep,
    taxTransferDataStep,
    taxSummaryDataStep,
    TransferInfoSidebar,
  },
  data() {
    return {
      preloader: true,
      disabledBtn: false,
      clonedData: null,
      loggedUser: null,
      wizardError: false,
      finalModel: {
        applicantUserAccountId: "",
        applicantFirstNameField: "",
        applicantLastNameField: "",
        applicantEmailField: "",
        companyId: "",
        companyNameField: "",
        companyAddressField: "",
        companyTaxNumberField: "",
        companyAccountSelectValueField: {
          index: null,
          name: "",
          description: "",
        },
        companyTaxGroupValueField: {
          index: null,
          name: "",
        },
        companyTaxFormSymbolValueField: {
          index: null,
          name: "",
          description: "",
        },
        companyCitOneTimeOrPermanentValueField: {
          index: null,
          name: "",
        },
        providerNipField: "",
        providerNameField: "",
        providerAddressField: "",
        providerAddressCity: "",
        providerAddressStreet: "",
        providerAddressHouseNo: "",
        providerAddressFlatNo: "",
        providerAddressPostalCode: "",
        providerAddressInternational: "",
        providerAccountField: "",
        providerFormDataModified: false,
        providerWithoutTaxNumber: false,
        transferDateOfTaxField: "",
        transferPeriodTypeValueField: "",
        transferSettleYearField: null,
        transferPeriodSettleHalfYearValueField: null,
        transferPeriodSettleQuarterValueField: null,
        transferPeriodSettleMonthValueField: null,
        transferPeriodSettleDecadeValueField: null,
        transferSettleDayField: null,
        transferGrossAmountField: null,
        transferDateOfOperationField: "",
        transferDateOfOperationEndField: "",
        transferApproverSelectField: {
          index: null,
          userAccountId: "",
          isActive: false,
          name: "",
          company: "",
          email: "",
          phone: "",
          registered: "",
        },
        transferAdditionalInfoField: "",
        transferRequestForConfirmationField: null,
        transferAttachmentsField: [],
        currencyFilter: null,
      },
      title: "Przelew skarbowy",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Przelew skarbowy",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    if (localStorage.getItem("cloneApplicationAction") !== null) {
      this.clonedData = JSON.parse(
        localStorage.getItem("cloneApplicationAction")
      );
      localStorage.removeItem("cloneApplicationAction");
    }
    setTimeout(() => {
      this.preloader = false;
    }, 1000);
  },
  methods: {
    ...authComputed,
    validateStep(name) {
      return this.$refs[name].validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        this.wizardError = false;
        this.finalModel = Object.assign({}, this.finalModel, model);
      } else {
        this.wizardError = true;
      }
    },
    onComplete: function () {
      this.disabledBtn = true;
      this.preloader = true;

      let applicationData = {
        typeOfApplication: 3,
        providerName: this.finalModel.providerNameField,
        // providerAddress: this.finalModel.providerAddressField,

        providerAddressCity: this.finalModel.providerAddressCity,
        providerAddressStreet: this.finalModel.providerAddressStreet,
        providerAddressHouseNo: this.finalModel.providerAddressHouseNo,
        providerAddressFlatNo: this.finalModel.providerAddressFlatNo,
        providerAddressPostalCode: this.finalModel.providerAddressPostalCode,
        providerAddressInternational: this.finalModel.providerAddressInternational,

        // providerNip: this.finalModel.providerWithoutTaxNumber ? null : this.finalModel.providerTaxNumberField,
        providerBankAccountNumber: this.finalModel.providerAccountField,
        // providerWithoutTaxNumber: this.finalModel.providerWithoutTaxNumber,
        changeDataManually: this.finalModel.providerFormDataModified,
        applicantUserAccountId: this.finalModel.applicantUserAccountId,
        acceptorUserAccountId: this.finalModel.transferApproverSelectField.userAccountId,
        transfer: this.finalModel.transferTitleField,
        companyId: this.finalModel.companyId,
        grossAmount: this.finalModel.transferGrossAmountField,
        dateOfOperation: this.finalModel.transferDateOfOperationField,
        typeOfBankAccount: this.finalModel.companyAccountSelectValueField.index,
        additionalInfo: this.finalModel.transferAdditionalInfoField,
        confirmTransfer: this.finalModel.transferRequestForConfirmationField,
        taxGroupSymbol: this.finalModel.companyTaxGroupValueField.name,
        taxFormSymbol: this.finalModel.companyTaxFormSymbolValueField.name,
        isRecurring: this.finalModel.companyCitOneTimeOrPermanentValueField.index,
        schedule: this.scheduleDates(this.timetable),
        periodType: this.finalModel.transferPeriodTypeValueField.symbol,
        periodTypeNumber: this.getPeriodTypeNumber,
        periodYear: this.getPeriodYear,
        cycleGenerationDay: this.finalModel.transferCycleDayStartValueField.symbol,
        endDateOfTheCycle:
          this.finalModel.companyCitOneTimeOrPermanentValueField.index === 1
            ? this.finalModel.transferDateOfOperationEndField
            : null,
        attachments: this.finalModel.transferAttachmentsField,
      };
      PaymentApplicationRepository.create(applicationData)
        .then((response) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES! ",
            text: "Wniosek przelewu skarbowego został wysłany",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "home" });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            toast: false,
            position: "top",
            title: "Błąd! ",
            text:
              "Nie można złożyć wniosku o przelew skarbowy: " +
              error.response.data.message,
            showConfirmButton: false,
            timer: 3500,
            // onClose: () => {
            //   this.$router.push({ name: "home"})
            // }
          });
          console.log(error);
        });
    },
    createScheduleHalfYear(symbol, period) {
      let cycle = [];

      let startDate = moment(this.finalModel.transferDateOfOperationField).format("YYYY-MM-DD");
      let endDate = moment(this.finalModel.transferDateOfOperationEndField).format("YYYY-MM-DD");
      let option = this.finalModel.transferCycleDayStartValueField ? this.finalModel.transferCycleDayStartValueField : "R";

      let difference = moment(endDate).diff(startDate, symbol) / 2;

      if (option.symbol === "R") {
        for (let i = 0; i <= difference; i++) {
          cycle.push(moment(startDate).add(i * 2, symbol));
        }
      } else if (option.symbol === "O") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            const half = (moment(startDate).add(i * 2, symbol)).format("Q") < 3 ? 1 : 2;
            if (half == 1 && moment(moment(startDate).add(i * 2, symbol).set(period, 2).endOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 2, symbol).set(period, 2).endOf(period));
            } else if (half == 2 && moment(moment(startDate).add(i * 2, symbol).set(period, 4).endOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 2, symbol).set(period, 4).endOf(period));
            }
          }
        }
      } else if (option.symbol === "P") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            const half = (moment(startDate).add(i * 2, symbol)).format("Q") < 3 ? 1 : 2;
            if (half == 1 && moment(moment(startDate).add(i * 2, symbol).set(period, 1).startOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 2, symbol).set(period, 1).startOf(period));
            } else if (half == 2 && moment(moment(startDate).add(i * 2, symbol).set(period, 3).startOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 2, symbol).set(period, 3).startOf(period));
            }
          }
        }
      }

      return cycle;

    },

    createScheduleDecade(symbol, period) {
      let cycle = [];

      let startDate = moment(this.finalModel.transferDateOfOperationField).format("YYYY-MM-DD");
      let endDate = moment(this.finalModel.transferDateOfOperationEndField).format("YYYY-MM-DD");
      let option = this.finalModel.transferCycleDayStartValueField ? this.finalModel.transferCycleDayStartValueField : "R";

      let difference = moment(endDate).diff(startDate, symbol) / 10;

      if (option.symbol === "R") {
        for (let i = 0; i <= difference; i++) {
          cycle.push(moment(startDate).add(i * 10, symbol));
        }
      } else if (option.symbol === "O") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            const dayNumber = (moment(startDate).add(i * 10, symbol)).format("D")
            const decadeNumber = dayNumber < 11 ? 1 : dayNumber < 21 ? 2 : 3;

            if (decadeNumber == 1 && moment(moment(startDate).add(i * 10, symbol).date(10).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(10));
            } else if (decadeNumber == 2 && moment(moment(startDate).add(i * 10, symbol).date(20).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(20));
            } else if (decadeNumber == 3 && moment(moment(startDate).add(i * 10, symbol).date(31).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(31));
            }
          }
        }
      } else if (option.symbol === "P") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            const dayNumber = (moment(startDate).add(i * 10, symbol)).format("D")
            const decadeNumber = dayNumber < 10 ? 1 : dayNumber < 20 ? 2 : 3;
            if (decadeNumber == 1 && moment(moment(startDate).add(i * 10, symbol).date(1).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(1));
            } else if (decadeNumber == 2 && moment(moment(startDate).add(i * 10, symbol).date(11).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(11));
            } else if (decadeNumber == 3 && moment(moment(startDate).add(i * 10, symbol).date(21).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i * 10, symbol).date(21));
            }
          }
        }
      }
      return cycle;
    },

    createSchedule(symbol, period) {
      let cycle = [];

      let startDate = moment(this.finalModel.transferDateOfOperationField).format("YYYY-MM-DD");
      let endDate = moment(this.finalModel.transferDateOfOperationEndField).format("YYYY-MM-DD");
      let option = this.finalModel.transferCycleDayStartValueField ? this.finalModel.transferCycleDayStartValueField : "R";

      let difference = moment(endDate).diff(startDate, period);

      if (option.symbol === "R") {
        for (let i = 0; i <= difference; i++) {
          cycle.push(moment(startDate).add(i, symbol));
        }
      } else if (option.symbol === "O") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            if (moment(moment(startDate).add(i, symbol).endOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i, symbol).endOf(period));
            }
          }
        }
      } else if (option.symbol === "P") {
        for (let i = 0; i <= difference; i++) {
          if (i == 0) {
            cycle.push(moment(startDate).add(0, symbol));
          } else {
            if (moment(moment(startDate).add(i, symbol).startOf(period).format("YYYY-MM-DD")).isSameOrBefore(endDate)) {
              cycle.push(moment(startDate).add(i, symbol).startOf(period));
            }
          }
        }
      }

      return cycle;
    },
    scheduleDates(timetable) {
      let formatedDates = [];
      for (let i = 0; i < timetable.length; i++) {
        formatedDates.push(moment(timetable[i]).format("YYYY-MM-DD"));
      }
      return formatedDates;
    },
  },
  computed: {
    timetable: function () {
      let schedule = [];

      switch (this.finalModel.transferPeriodTypeValueField.symbol) {
      case "R":
        schedule = this.createSchedule("Y", "year");
        break;
      case "P":
        schedule = this.createScheduleHalfYear("Q", "quarter");
        break;
      case "K":
        schedule = this.createSchedule("Q", "quarter");
        break;
      case "M":
        schedule = this.createSchedule("M", "month");
        break;
      case "D":
        schedule = this.createScheduleDecade("days", "day");
        break;
      case "J":
        schedule = this.createSchedule("days", "day");
        break;
      case '0':
      default:
        break;
      }

      return schedule;
    },
    getPeriodYear: function () {
      let year = this.finalModel.transferSettleYearField || this.finalModel.transferPeriodSettleMonthValueField || this.finalModel.transferSettleDayField;
      return year == null ? null : moment(year).format("YY");
    },
    getPeriodTypeNumber: function () {
      let periodNum = null;
      const periodType = this.finalModel.transferPeriodTypeValueField ? this.finalModel.transferPeriodTypeValueField.symbol : null;
      const _yearDate = this.finalModel.transferSettleYearField ? this.finalModel.transferSettleYearField : moment(new Date());
      const _halfyearSymbol = this.finalModel.transferPeriodSettleHalfYearValueField ? this.finalModel.transferPeriodSettleHalfYearValueField.symbol : "";
      const _quarterSymbol = this.finalModel.transferPeriodSettleQuarterValueField ? this.finalModel.transferPeriodSettleQuarterValueField.symbol : "";
      const _monthDate = this.finalModel.transferPeriodSettleMonthValueField ? this.finalModel.transferPeriodSettleMonthValueField : moment(new Date());
      const _decadeSymbol = this.finalModel.transferPeriodSettleDecadeValueField ? this.finalModel.transferPeriodSettleDecadeValueField.symbol : "";
      const _dayDate = this.finalModel.transferSettleDayField ? this.finalModel.transferSettleDayField : moment(new Date());

      switch (periodType) {
      case "P":
        periodNum = _halfyearSymbol;
        break;
      case "K":
        periodNum = _quarterSymbol;
        break;
      case "M":
        periodNum = moment(_monthDate).format("MM");
        break;
      case "D":
        periodNum = _decadeSymbol + moment(_monthDate).format("MM");
        break;
      case "J":
        periodNum = moment(_dayDate).format("DD") + moment(_dayDate).format("MM");
        break;
      default:
        periodNum = null;
        break;
      }
      return periodNum;
    },
    isApplicant() {
      return roleService.isApplicant();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="isApplicant">
      <div class="col-md-8">
        <div class="card shadow">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="media">
                  <img class="mr-3 mt-1" src="@/assets/images/si-pay-logo.png" alt="logo" height="30" />
                  <div class="media-body">
                    <h5 class="my-0">Wnioskujący : {{ loggedUser.firstName }} {{ loggedUser.lastName }}</h5>
                    <p class="small m-0 p-0">{{ loggedUser.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <b-link class="btn btn-sm btn-warning" :to="{ name: 'home' }">Anuluj wniosek</b-link>
              </div>
            </div>
            <hr />
            <b-alert v-if="wizardError" variant="danger" class="mb-3" show>
              <strong>Błąd!</strong> Coś poszło nie tak, uzupełnij formularz poprawnie i spróbuj ponownie.
            </b-alert>
            <form-wizard shape="circle" color="#556ee6" errorColor="#ffffff" back-button-text="Powrót" next-button-text="Dalej" finish-button-text="Wyślij wniosek" @on-complete="onComplete">
              <!-- step 1 -->
              <!-- applicant -->
              <tab-content title="Wnioskujący" route="/tax-transfer/applicant" :before-change="()=>validateStep('taxApplicantDataStep')">
                <taxApplicantDataStep ref="taxApplicantDataStep" @on-validate="mergePartialModels"></taxApplicantDataStep>
              </tab-content>
              <!-- step 2 -->
              <!-- company -->
              <tab-content title="Dane firmy" route="/tax-transfer/company" :before-change="()=>validateStep('taxCompanyDataStep')">
                <taxCompanyDataStep ref="taxCompanyDataStep" @on-validate="mergePartialModels" :clonedData="clonedData"></taxCompanyDataStep>
              </tab-content>
              <!-- step 3 -->
              <!-- provider -->
              <tab-content title="Organ podatkowy" route="/tax-transfer/provider" :before-change="()=>validateStep('taxProviderDataStep')">
                <taxProviderDataStep ref="taxProviderDataStep" @on-validate="mergePartialModels" :companyId="finalModel.companyId" :taxGroupSymbol="finalModel.companyTaxGroupValueField" :clonedData="clonedData">
                </taxProviderDataStep>
              </tab-content>
              <!-- step 4 -->
              <!-- transfer -->
              <tab-content title="Dane do przelewu" route="/tax-transfer/transfer" :before-change="()=>validateStep('taxTransferDataStep')">
                <taxTransferDataStep ref="taxTransferDataStep" @on-validate="mergePartialModels" :companyId="this.finalModel.companyId" :oneTimeOrPermanent="this.finalModel.companyCitOneTimeOrPermanentValueField" :taxGroupSymbol="this.finalModel.companyTaxGroupValueField" :taxFormSymbol="this.finalModel.companyTaxFormSymbolValueField" :providerFormDataModified="this.finalModel.providerFormDataModified" :clonedData="clonedData"></taxTransferDataStep>
              </tab-content>
              <!-- step 5 -->
              <!-- summary -->
              <tab-content title="Podsumowanie" route="/tax-transfer/summary">
                <taxSummaryDataStep ref="taxSummaryDataStep" :summaryData="finalModel"></taxSummaryDataStep>
              </tab-content>
              <router-view></router-view>
            </form-wizard>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-0">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-playlist-star"></i>
                </span>
                <span class="d-none d-sm-inline-block">Dane wniosku</span>
              </template>
              <TransferInfoSidebar :finalModel="finalModel" :loggedUser="loggedUser" />
            </b-tab>
            <b-tab v-if="this.finalModel.companyCitOneTimeOrPermanentValueField.index === 1 && this.timetable.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="timetable.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <div class="card">
                <div class="card-header">
                  <strong><i class="mdi mdi-calendar-clock mr-2"></i>Harmonogram</strong> / Cykl : {{ this.finalModel.transferPeriodTypeValueField.symbol }} - {{ this.finalModel.transferPeriodTypeValueField.name}}
                </div>
                <div class="table-responsive mb-0">
                  <div data-simplebar class="conversation-list px-0">
                    <div class="simplebar-scroll-content">
                      <div class="simplebar-content">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Nr</th>
                              <th>Data</th>
                              <th>Dzień</th>
                              <th>Kwota</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(date, index) in timetable" :key="index">
                              <td>&nbsp;</td>
                              <td class="align-middle" scope="row">
                                <span class="badge badge-pill badge-soft-warning font-size-10">{{ index + 1 }}</span>
                              </td>
                              <td class="align-middle">
                                {{ date | formattedDate }}
                              </td>
                              <td class="align-middle">{{ new Date(date).toLocaleString('pl-PL', {weekday:'long'}) }}</td>
                              <td>{{ finalModel.transferGrossAmountField | currencyFilter }} PLN</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="list-group list-group-flush border-top">
                  <li class="list-group-item d-flex justify-content-between align-items-center"><span><i class="mdi mdi-transfer mr-2"></i>Wszystkich przelewów</span> <span>{{ timetable.length }}</span></li>
                  <li class="list-group-item d-flex justify-content-between align-items-center"><span><i class="mdi mdi-coin-outline mr-2"></i>Łączna kwota</span> <span>{{ (timetable.length * finalModel.transferGrossAmountField) | currencyFilter }} PLN</span></li>
                  <li class="list-group-item d-flex justify-content-between align-items-center"><span><i class="mdi mdi-timelapse mr-2"></i>W okresie</span> <span>{{ finalModel.transferDateOfOperationField | formattedDate }} - {{ finalModel.transferDateOfOperationEndField | formattedDate }}</span></li>
                  <li class="list-group-item d-flex justify-content-between align-items-center"><span><i class="mdi mdi-bank-transfer mr-2"></i>Zlecenie przelewu</span> <span>{{ this.finalModel.transferCycleDayStartValueField.name }}</span></li>
                </ul>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
