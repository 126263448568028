<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import vue2Dropzone from "vue2-dropzone";
import { appService } from "@/app/service/appService";
import {
  required,
  maxLength,
  minLength,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  typeOfTaxPeriods,
  typeOfTaxYPeriods,
  typeOfTaxQPeriods,
  typeOfTaxMPeriods,
  typeOfTaxDPeriods,
  typeOfTaxSettlement,
} from "@/data/data-type-of-tax-periods";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";
import moment from "moment";

const AcceptorRepository = Repository.get("AcceptorRepository");
const PaymentApplicationAttachmentRepository = Repository.get(
  "PaymentApplicationAttachmentRepository"
);

const alphaNumAndSpecials = helpers.regex(
  "alphaNumAndSpecials",
  /^[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ0-9\s,./_-]*$/
);

export default {
  name: "transferDataStep",
  props: {
    companyId: {
      type: String,
      required: true,
    },
    oneTimeOrPermanent: {
      type: Object,
      required: true,
    },
    taxGroupSymbol: {
      type: Object,
      required: true,
    },
    taxFormSymbol: {
      type: Object,
      required: true,
    },
    providerFormDataModified: {
      type: Boolean,
      required: true,
    },
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    DatePicker,
    Multiselect,
    Switches,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      inputLoading: false,
      undefinedCycle: false,
      transferApproverOptionsField: [],
      transferPeriodTypeOptionsField: typeOfTaxPeriods,
      transferPeriodSettleHalfYearOptionsField: typeOfTaxYPeriods,
      transferPeriodSettleQuarterOptionsField: typeOfTaxQPeriods,
      transferPeriodSettleDecadeOptionsField: typeOfTaxDPeriods,
      transferPeriodOptionsField: typeOfTaxMPeriods,
      transferCycleDayStartOptionsField: typeOfTaxSettlement,
      transferForm: {
        transferPeriodTypeValueField: [],
        transferSettleYearField: null,
        transferPeriodSettleHalfYearValueField: null,
        transferPeriodSettleQuarterValueField: null,
        transferPeriodSettleMonthValueField: null,
        transferPeriodSettleDecadeValueField: null,
        transferSettleDayField: null,
        transferCycleDayStartValueField: "",
        transferTitleField: "",
        transferGrossAmountField: null,
        transferDateOfOperationField: null,
        transferDateOfOperationEndField: null,
        transferApproverSelectField: "",
        transferAdditionalInfoField: "",
        transferRequestForConfirmationField: null,
        transferAttachmentsField: [],
      },
      dropzoneOptions: {
        url: PaymentApplicationAttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
    };
  },
  validations() {
    return {
      transferForm: {
        transferCycleDayStartValueField: {
          required: requiredIf(function () {
            return this.oneTimeOrPermanent.index == 1 ? true : false;
          }),
        },
        transferPeriodTypeValueField: {
          required,
          // required: requiredIf(function () {
          //   return this.oneTimeOrPermanent.index == 1 ? true : false;
          // }),
        },
        transferSettleYearField: {
          required: requiredIf(function () {
            const symR =
              this.transferForm.transferPeriodTypeValueField.symbol == "R"
                ? true
                : false;
            const symP =
              this.transferForm.transferPeriodTypeValueField.symbol == "P"
                ? true
                : false;
            const symK =
              this.transferForm.transferPeriodTypeValueField.symbol == "K"
                ? true
                : false;
            return symR || symP || symK;
          }),
        },
        transferPeriodSettleHalfYearValueField: {
          required: requiredIf(function () {
            return this.transferForm.transferPeriodTypeValueField.symbol == "P"
              ? true
              : false;
          }),
        },
        transferPeriodSettleQuarterValueField: {
          required: requiredIf(function () {
            return this.transferForm.transferPeriodTypeValueField.symbol == "K"
              ? true
              : false;
          }),
        },
        transferPeriodSettleMonthValueField: {
          required: requiredIf(function () {
            const symD =
              this.transferForm.transferPeriodTypeValueField.symbol == "D"
                ? true
                : false;
            const symM =
              this.transferForm.transferPeriodTypeValueField.symbol == "M"
                ? true
                : false;
            return symD || symM;
          }),
        },
        transferPeriodSettleDecadeValueField: {
          required: requiredIf(function () {
            const symD =
              this.transferForm.transferPeriodTypeValueField.symbol == "D"
                ? true
                : false;
            return symD;
          }),
        },
        transferSettleDayField: {
          required: requiredIf(function () {
            const symJ =
              this.transferForm.transferPeriodTypeValueField.symbol == "J"
                ? true
                : false;
            return symJ;
          }),
        },
        transferTitleField: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(20),
          alphaNumAndSpecials,
        },
        transferGrossAmountField: {
          required,
          maxLength: maxLength(13),
          valueGreaterZero: (value) => {
            if (value > 0) {
              return true;
            }
            return false;
          },
        },
        transferDateOfOperationField: {
          required,
        },
        transferDateOfOperationEndField: {
          required: requiredIf(function () {
            return this.oneTimeOrPermanent.index == 1 ? true : false;
          }),
        },
        transferApproverSelectField: {
          required,
        },
        transferAdditionalInfoField: {
          maxLength: maxLength(500),
        },
        transferRequestForConfirmationField: {},
        transferAttachmentsField: [],
      },
    };
  },
  created() {
    if (this.clonedData !== null) {

      this.transferForm.transferTitleField = this.clonedData.transfer;
      this.transferForm.transferGrossAmountField = parseFloat(this.clonedData.grossAmount);
      this.transferForm.transferRequestForConfirmationField = this.clonedData.confirmTransfer;
      this.transferForm.transferAdditionalInfoField = this.clonedData.additionalInfo;

      let periodGroupArr = this.transferPeriodTypeOptionsField.filter((obj) => {
        return (obj.symbol === this.clonedData.applicationSpecificData.period.type);
      });
      this.transferForm.transferPeriodTypeValueField = periodGroupArr[0];

      let cycleGroupArr = this.transferCycleDayStartOptionsField.filter((obj) => {
        return (obj.symbol === this.clonedData.applicationSpecificData.period.cycleGenerationDay);
      });
      this.transferForm.transferCycleDayStartValueField = cycleGroupArr[0];

      switch (this.clonedData.applicationSpecificData.period.type) {
      case "R":
        this.transferForm.transferSettleYearField = moment().year('20' + this.clonedData.applicationSpecificData.period.year).format('YYYY')
        break;
      case "P": {
        this.transferForm.transferSettleYearField = moment().year('20' + this.clonedData.applicationSpecificData.period.year).format('YYYY')
        let halfYearGroupArr = this.transferPeriodSettleHalfYearOptionsField.filter((obj) => {
          return (obj.symbol === this.clonedData.applicationSpecificData.period.typeNumber);
        });
        this.transferForm.transferPeriodSettleHalfYearValueField = halfYearGroupArr[0];
        break;
      }
      case "K": {
        this.transferForm.transferSettleYearField = moment().year('20' + this.clonedData.applicationSpecificData.period.year).format('YYYY')
        let quarterGroupArr = this.transferPeriodSettleQuarterOptionsField.filter((obj) => {
          return (obj.symbol === this.clonedData.applicationSpecificData.period.typeNumber);
        });
        this.transferForm.transferPeriodSettleQuarterValueField = quarterGroupArr[0];
        break;
      }
      case "M": {
        let _year = '20' + this.clonedData.applicationSpecificData.period.year;
        let _month = this.clonedData.applicationSpecificData.period.typeNumber.substring(0,2).replace(/^0+/, '') - 1;
        this.transferForm.transferPeriodSettleMonthValueField = moment().year(_year).month(_month).format('YYYY-MM');
        break;
      }
      case "D": {
        let _year = '20' + this.clonedData.applicationSpecificData.period.year;
        let _month = this.clonedData.applicationSpecificData.period.typeNumber.substring(2).replace(/^0+/, '') - 1;
        this.transferForm.transferPeriodSettleMonthValueField = moment().year(_year).month(_month).format('YYYY-MM');
        let decadeGroupArr = this.transferPeriodSettleDecadeOptionsField.filter((obj) => {
          return (obj.symbol === this.clonedData.applicationSpecificData.period.typeNumber.substring(0,2));
        });
        this.transferForm.transferPeriodSettleDecadeValueField = decadeGroupArr[0];
        break;
      }
      case "J":{
        let _year = '20' + this.clonedData.applicationSpecificData.period.year;
        let _month = this.clonedData.applicationSpecificData.period.typeNumber.substring(2).replace(/^0+/, '') - 1;
        let _day = this.clonedData.applicationSpecificData.period.typeNumber.substring(0,2).replace(/^0+/, '');
        this.transferForm.transferSettleDayField = moment().year(_year).month(_month).date(_day).format('YYYY-MM-DD');
        break;
      }
      default:
        break;
      }
    }
  },
  methods: {
    validate() {
      this.$v.transferForm.$touch();
      var isValid = !this.$v.transferForm.$invalid;
      this.$emit("on-validate", this.$data.transferForm, isValid);
      return isValid;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(this.transferForm.transferDateOfOperationField);
    },
    userLabel({ name, company, email }) {
      return `${name} ${company} ${email}`;
    },
    periodLabel({ index, symbol, name }) {
      return `${index} ${symbol} ${name}`;
    },
    compareCycleDates() {
      if (this.transferForm.transferDateOfOperationField > this.transferForm.transferDateOfOperationEndField) {
        this.transferForm.transferDateOfOperationEndField = this.transferForm.transferDateOfOperationField;
      }
    },
    clearFields() {
      this.transferForm.transferSettleYearField = null;
      this.transferForm.transferPeriodSettleHalfYearValueField = null;
      this.transferForm.transferPeriodSettleQuarterValueField = null;
      this.transferForm.transferPeriodSettleMonthValueField = null;
      this.transferForm.transferPeriodSettleDecadeValueField = null;
      this.transferForm.transferSettleDayField = null;
      this.transferForm.transferTitleField = null;
      this.undefinedCycle = false;

      if (this.transferForm.transferPeriodTypeValueField && this.transferForm.transferPeriodTypeValueField.symbol == "0") {
        this.transferForm.transferTitleField = this.taxFormSymbol.name + "-0";
        this.undefinedCycle = true;
        this.transferForm.transferCycleDayStartValueField = { "index": 3, "symbol": "R", "name": "Według dnia daty rozpoczęcia cyklu" };
      }
      if (this.transferForm.transferPeriodTypeValueField && this.transferForm.transferPeriodTypeValueField.symbol == "J") {
        this.undefinedCycle = true;
        this.transferForm.transferCycleDayStartValueField = { "index": 3, "symbol": "R", "name": "Według dnia daty rozpoczęcia cyklu" };
      }
    },
    populateAction() {
      this.transferForm.transferTitleField = this.taxFormSymbol.name + " " + (this.codeNumber || '')
    },
    setPeriodSelect() {
      // block period select with no-period = 0 selected
      if(this.taxFormSymbol.name && this.taxFormSymbol.name == 'CIT6R') {
        this.clearFields();
        this.transferForm.transferPeriodTypeValueField = { index: 7, symbol: "0", name: "Brak okresu" };
        this.transferForm.transferTitleField = this.taxFormSymbol.name + "-0";
      }
    },
    dropzoneAfterSuccess(file, response) {
      this.transferForm.transferAttachmentsField.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.transferForm.transferAttachmentsField.length; i++) {
        if (!this.transferForm.transferAttachmentsField.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.transferForm.transferAttachmentsField[i] === file.tempUploadUuid
          ) {
            this.transferForm.transferAttachmentsField.splice(i, 1);
          }
        }
      }
    },
    getAcceptorsForCompany() {
      this.inputLoading = true;
      AcceptorRepository.getAllAcceptors(this.companyId)
        .then((response) => {
          this.transferApproverOptionsField = response.data;
          // absence ad user mapping
          // this.transferApproverOptionsField = this.transferApproverOptionsField.map(v => ({...v, $isDisabled: v.isBusy == true}))
          if (this.clonedData !== null) {
            let userArr = this.transferApproverOptionsField.filter((obj) => {
              return (
                obj.userAccountId === this.clonedData.acceptorUserAccountId
              );
            });
            this.transferForm.transferApproverSelectField = userArr[0];
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.getAcceptorsForCompany();
      }
    },
    providerFormDataModified: function (newVal, oldVal) {
      if (newVal !== "") {
        this.getAcceptorsForCompany();
      }
    },
    taxFormSymbol: function () {
      this.populateAction();
      this.setPeriodSelect();
    },
  },
  computed: {
    codeNumber() {
      return appService.getPeriodTypeNumber(this.transferForm)
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-card bg-variant="soft-info">
        <div class="form-row">
          <div class="form-group col" :class="{ 'is-invalid' : $v.transferForm.transferPeriodTypeValueField.$error }">
            <label for="transferPeriodTypeValueField">
              Okres zobowiązania skarbowego
              <em class="text-danger">*</em>
            </label>
            <multiselect id="transferPeriodTypeValueField" v-model.trim="transferForm.transferPeriodTypeValueField" :options="transferPeriodTypeOptionsField" :allow-empty="false" :custom-label="periodLabel" track-by="index" @input="clearFields" :class="{ 'is-invalid': $v.transferForm.transferPeriodTypeValueField.$error, 'is-valid': !$v.transferForm.transferPeriodTypeValueField.$invalid && !$v.transferForm.transferPeriodTypeValueField.$error }" name="transferPeriodTypeValueField" placeholder="Wybierz rodzaj rozliczenia" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" :disabled="taxFormSymbol.name == 'CIT6R'" value required>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </div>
              </template>
              <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
            </multiselect>
            <template v-if="$v.transferForm.transferPeriodTypeValueField.$error">
              <small class="text-danger">Pole jest wymagane!</small>
            </template>
          </div>

          <div class="form-group col" v-if="transferForm.transferPeriodTypeValueField && (transferForm.transferPeriodTypeValueField.symbol == 'R' || transferForm.transferPeriodTypeValueField.symbol == 'P' || transferForm.transferPeriodTypeValueField.symbol == 'K')">
            <label for="transferSettleYearField">
              Rok
              <em class="text-danger">*</em>
            </label>
            <date-picker id="transferSettleYearField" v-model="transferForm.transferSettleYearField" format="YYYY" type="year" value-type="format" :editable="false" :first-day-of-week="1" @input="populateAction" :class="{ 'is-invalid': $v.transferForm.transferSettleYearField.$error, 'is-valid': !$v.transferForm.transferSettleYearField.$invalid && !$v.transferForm.transferSettleYearField.$error }" lang="pl" placeholder="Data w formacie YYYY" />
            <div v-if="$v.transferForm.transferSettleYearField.$invalid" class="invalid-feedback">
              <span class="d-block" v-if="$v.transferForm.transferSettleYearField.$invalid && !$v.transferForm.transferSettleYearField.required">Pole jest wymagane!</span>
            </div>
          </div>

          <!-- Półrocze  -->
          <div class="form-group col" :class="{ 'is-invalid' : $v.transferForm.transferPeriodSettleHalfYearValueField.$error }" v-if="transferForm.transferPeriodTypeValueField && transferForm.transferPeriodTypeValueField.symbol == 'P'">
            <label for="transferPeriodSettleHalfYearValueField">
              Półrocze
              <em class="text-danger">*</em>
            </label>
            <multiselect id="transferPeriodSettleHalfYearValueField" v-model.trim="transferForm.transferPeriodSettleHalfYearValueField" @input="populateAction" :options="transferPeriodSettleHalfYearOptionsField" :allow-empty="false" :custom-label="periodLabel" track-by="index" :class="{ 'is-invalid': $v.transferForm.transferPeriodSettleHalfYearValueField.$error, 'is-valid': !$v.transferForm.transferPeriodSettleHalfYearValueField.$invalid && !$v.transferForm.transferPeriodSettleHalfYearValueField.$error }" name="transferPeriodSettleHalfYearValueField" placeholder="Wybierz rodzaj rozliczenia" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </div>
              </template>
              <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
            </multiselect>
            <template v-if="$v.transferForm.transferPeriodSettleHalfYearValueField.$error">
              <small class="text-danger">Pole jest wymagane!</small>
            </template>
          </div>

          <!-- Kwartał  -->
          <div class="form-group col" :class="{ 'is-invalid' : $v.transferForm.transferPeriodSettleQuarterValueField.$error }" v-if="transferForm.transferPeriodTypeValueField && transferForm.transferPeriodTypeValueField.symbol == 'K'">
            <label for="transferPeriodSettleQuarterValueField">
              Kwartał
              <em class="text-danger">*</em>
            </label>
            <multiselect id="transferPeriodSettleQuarterValueField" v-model.trim="transferForm.transferPeriodSettleQuarterValueField" @input="populateAction" :options="transferPeriodSettleQuarterOptionsField" :allow-empty="false" :custom-label="periodLabel" track-by="index" :class="{ 'is-invalid': $v.transferForm.transferPeriodSettleQuarterValueField.$error, 'is-valid': !$v.transferForm.transferPeriodSettleQuarterValueField.$invalid && !$v.transferForm.transferPeriodSettleQuarterValueField.$error }" name="transferPeriodSettleQuarterValueField" placeholder="Wybierz rodzaj rozliczenia" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </div>
              </template>
              <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
            </multiselect>
            <template v-if="$v.transferForm.transferPeriodSettleQuarterValueField.$error">
              <small class="text-danger">Pole jest wymagane!</small>
            </template>
          </div>

          <!-- Miesiąc -->
          <div class="form-group col" v-if="transferForm.transferPeriodTypeValueField && (transferForm.transferPeriodTypeValueField.symbol == 'M' || transferForm.transferPeriodTypeValueField.symbol == 'D')">
            <label for="transferPeriodSettleMonthValueField">
              Miesiąc
              <em class="text-danger">*</em>
            </label>
            <date-picker id="transferPeriodSettleMonthValueField" v-model="transferForm.transferPeriodSettleMonthValueField" @input="populateAction" format="YYYY-MM" type="month" value-type="format" :editable="false" :first-day-of-week="1" :class="{ 'is-invalid': $v.transferForm.transferPeriodSettleMonthValueField.$error, 'is-valid': !$v.transferForm.transferPeriodSettleMonthValueField.$invalid && !$v.transferForm.transferPeriodSettleMonthValueField.$error }" lang="pl" placeholder="Data w formacie YYYY-MM" />
            <div v-if="$v.transferForm.transferPeriodSettleMonthValueField.$invalid" class="invalid-feedback">
              <span class="d-block" v-if="$v.transferForm.transferPeriodSettleMonthValueField.$invalid && !$v.transferForm.transferPeriodSettleMonthValueField.required">Pole jest wymagane!</span>
            </div>
          </div>

          <!-- Dekada miesiąca  -->
          <div class="form-group col" :class="{ 'is-invalid' : $v.transferForm.transferPeriodSettleDecadeValueField.$error }" v-if="transferForm.transferPeriodTypeValueField && transferForm.transferPeriodTypeValueField.symbol == 'D'">
            <label for="transferPeriodSettleDecadeValueField">
              Dekada miesiąca
              <em class="text-danger">*</em>
            </label>
            <multiselect id="transferPeriodSettleDecadeValueField" v-model.trim="transferForm.transferPeriodSettleDecadeValueField" @input="populateAction" :options="transferPeriodSettleDecadeOptionsField" :allow-empty="false" :custom-label="periodLabel" track-by="index" :class="{ 'is-invalid': $v.transferForm.transferPeriodSettleDecadeValueField.$error, 'is-valid': !$v.transferForm.transferPeriodSettleDecadeValueField.$invalid && !$v.transferForm.transferPeriodSettleDecadeValueField.$error }" name="transferPeriodSettleDecadeValueField" placeholder="Wybierz rodzaj rozliczenia" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
                </div>
              </template>
              <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
            </multiselect>
            <template v-if="$v.transferForm.transferPeriodSettleDecadeValueField.$error">
              <small class="text-danger">Pole jest wymagane!</small>
            </template>
          </div>

          <!-- Dzień -->
          <div class="form-group col" v-if="transferForm.transferPeriodTypeValueField && transferForm.transferPeriodTypeValueField.symbol == 'J'">
            <label for="transferSettleDayField">
              Dzień
              <em class="text-danger">*</em>
            </label>
            <date-picker id="transferSettleDayField" v-model="transferForm.transferSettleDayField" format="YYYY-MM-DD" value-type="format" :editable="false" :first-day-of-week="1" @input="populateAction" :class="{ 'is-invalid': $v.transferForm.transferSettleDayField.$error, 'is-valid': !$v.transferForm.transferSettleDayField.$invalid && !$v.transferForm.transferSettleDayField.$error }" lang="pl" placeholder="Data w formacie YYYY-MM-DD" />
            <div v-if="$v.transferForm.transferSettleDayField.$invalid" class="invalid-feedback">
              <span class="d-block" v-if="$v.transferForm.transferSettleDayField.$invalid && !$v.transferForm.transferSettleDayField.required">Pole jest wymagane!</span>
            </div>
          </div>
        </div>
      </b-card>

      <div class="form-row">
        <div class="form-group col" :class="{ 'is-invalid' : $v.transferForm.transferCycleDayStartValueField.$error }" v-if="oneTimeOrPermanent.index === 1">
          <label for="transferCycleDayStartValueField">
            Dzień zlecenia przelewu w cyklu
            <em class="text-danger">*</em>
          </label>
          <multiselect id="transferCycleDayStartValueField" v-model.trim="transferForm.transferCycleDayStartValueField" :options="transferCycleDayStartOptionsField" :allow-empty="false" :custom-label="periodLabel" track-by="name" @select="$v.transferForm.transferCycleDayStartValueField.$reset()" :class="{ 'is-invalid': $v.transferForm.transferCycleDayStartValueField.$error, 'is-valid': !$v.transferForm.transferCycleDayStartValueField.$invalid && !$v.transferForm.transferCycleDayStartValueField.$error }" name="transferCycleDayStartValueField" placeholder="Wybierz okres rozliczenia" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" :disabled="undefinedCycle" value required>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.symbol }} - {{ props.option.name }}</span>
              </div>
            </template>
            <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
          </multiselect>
          <template v-if="$v.transferForm.transferCycleDayStartValueField.$error">
            <small class="text-danger">Pole jest wymagane!</small>
          </template>
        </div>
        <div class="form-group col">
          <label for="transferDateOfOperationField">
            {{ oneTimeOrPermanent.index === 1 ? 'Data pierwszej operacji' : 'Data operacji'}}
            <em class="text-danger">*</em>
          </label>
          <date-picker id="transferDateOfOperationField" v-model="transferForm.transferDateOfOperationField" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeToday" @input="compareCycleDates" :class="{ 'is-invalid': $v.transferForm.transferDateOfOperationField.$error, 'is-valid': !$v.transferForm.transferDateOfOperationField.$invalid && !$v.transferForm.transferDateOfOperationField.$error }" lang="pl" placeholder="Data w formacie YYYY-MM-DD" />
          <small id="transferDateOfOperationFieldHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Data operacji nie jest datą realizacji przelewu</small>
          <div v-if="$v.transferForm.transferDateOfOperationField.$invalid" class="invalid-feedback">
            <span class="d-block" v-if="$v.transferForm.transferDateOfOperationField.$invalid && !$v.transferForm.transferDateOfOperationField.required">Pole jest wymagane!</span>
          </div>
        </div>
        <div class="form-group col" v-if="oneTimeOrPermanent.index === 1">
          <label for="transferDateOfOperationEndField">
            Wybierz datę zakończenia cyklu
            <em class="text-danger">*</em>
          </label>
          <date-picker id="transferDateOfOperationEndField" v-model="transferForm.transferDateOfOperationEndField" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeTodayAndAfterAWeek" @input="$v.transferForm.transferDateOfOperationEndField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferDateOfOperationEndField.$error, 'is-valid': !$v.transferForm.transferDateOfOperationEndField.$invalid && !$v.transferForm.transferDateOfOperationEndField.$error }" lang="pl" placeholder="Data w formacie YYYY-MM-DD" />
          <div v-if="$v.transferForm.transferDateOfOperationEndField.$invalid" class="invalid-feedback">
            <span class="d-block" v-if="$v.transferForm.transferDateOfOperationEndField.$invalid && !$v.transferForm.transferDateOfOperationEndField.required">Pole jest wymagane!</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="transferTitleField">
          Identyfikacja zobowiązania
          <em class="text-danger">*</em>
        </label>
        <input id="transferTitleField" v-model.trim="transferForm.transferTitleField" @input="$v.transferForm.transferTitleField.$touch()" class="form-control" :class="{ 'is-invalid': $v.transferForm.transferTitleField.$error, 'is-valid': !$v.transferForm.transferTitleField.$invalid && !$v.transferForm.transferTitleField.$error }" type="text" name="transferTitleField" placeholder="Wpisz tytuł przelewu" value required />
        <div v-if="$v.transferForm.transferTitleField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.minLength">Zbyt mała ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.alphaNumAndSpecials">Wpisano niedozwolone znaki!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="transferGrossAmountField">
          Kwota przelewu w PLN
          <em class="text-danger">*</em>
        </label>
        <currency-input id="transferGrossAmountField" v-model.trim="transferForm.transferGrossAmountField" :value-range="{min:0.0, max:99999999.99}" :allow-negative="false" :distraction-free="false" class="form-control" @input="$v.transferForm.transferGrossAmountField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferGrossAmountField.$error, 'is-valid': !$v.transferForm.transferGrossAmountField.$invalid && !$v.transferForm.transferGrossAmountField.$error }" placeholder="Wpisz kwotę przelewu w PLN" value />
        <div v-if="$v.transferForm.transferGrossAmountField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.valueGreaterZero">Podana wartość jest niepoprawna!</span>
        </div>
      </div>
      <div class="form-group" :class="{ 'is-invalid' : $v.transferForm.transferApproverSelectField.$error }">
        <label for="transferApproverSelectField">
          Wybierz akceptującego wniosek
          <em class="text-danger">*</em>
        </label>
        <multiselect id="transferApproverSelectField" v-model.trim="transferForm.transferApproverSelectField" :options="transferApproverOptionsField" :custom-label="userLabel" track-by="email" @input="$v.transferForm.transferApproverSelectField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferApproverSelectField.$error, 'is-valid': !$v.transferForm.transferApproverSelectField.$invalid && !$v.transferForm.transferApproverSelectField.$error }" name="transferApproverSelectField" placeholder="Wybierz użytkownika z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
              <br />
              <span class="option__small small text-dark">
                <span v-if="props.option.isBusy" class="badge badge-pill badge-soft-danger font-size-10" >Na urlopie</span>
                {{ props.option.email }} {{ props.option.company }}
              </span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.transferForm.transferApproverSelectField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
      <div class="form-group">
        <label for="transferAdditionalInfoField">Dodatkowa informacja</label>
        <textarea id="transferVatAmountField" v-model.trim="transferForm.transferAdditionalInfoField" @input="$v.transferForm.transferAdditionalInfoField.$touch()" class="form-control" :class="{ 'is-invalid': $v.transferForm.transferAdditionalInfoField.$error, 'is-valid': !$v.transferForm.transferAdditionalInfoField.$error && !$v.transferForm.transferAdditionalInfoField.$error }" type="text" placeholder="Wpisz dodatkowe uwagi do wniosku" value></textarea>
        <div v-if="$v.transferForm.transferAdditionalInfoField.$invalid" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferAdditionalInfoField.$invalid && !$v.transferForm.transferAdditionalInfoField.maxLength">Przekroczona dozwolona ilość znaków!</span>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <switches class="mt-2" v-model="transferForm.transferRequestForConfirmationField" type-bold="false" :color="transferForm.transferRequestForConfirmationField == 1 ? 'success' : 'primary'"></switches>
        <label class="mt-1 ml-3"><span class="mr-2">Potwierdzenie wykonania przelewu:</span>
          <span v-if="transferForm.transferRequestForConfirmationField" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
          <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
        </label>
      </div>
      <div class="form-group">
        <label for="dropzone">Załączniki</label>
        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true >
          <div class="dropzone-custom-content dz-message">
            upuść pliki tutaj lub kliknij, aby przesłać
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>
