<script>
import Multiselect from "vue-multiselect";
import {
  required,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { typeOfAccountData } from "@/data/data-type-of-account";
import {
  typeOfTaxFormPitData,
  typeOfTaxFormVatData,
  typeOfTaxFormCitData,
  typeOfTaxFormOtherData,
  typeOfTaxData,
} from "@/data/data-type-of-tax-form";
import InputTaxNumber from "@/components/form-elements/input-tax-number";
import Repository from "@/app/repository/repository-factory";

const ApplicantsCompanyRepository = Repository.get(
  "ApplicantsCompanyRepository"
);

const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

export default {
  name: "companyDataStep",
  props: {
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: { Multiselect, InputTaxNumber },
  data() {
    return {
      inputLoading: false,
      companyId: "",
      showTaxFormSelect: false,
      showCitOneTimeOrPermanentSelect: false,
      typeOfTaxFormPitData: typeOfTaxFormPitData,
      typeOfTaxFormVatData: typeOfTaxFormVatData,
      typeOfTaxFormCitData: typeOfTaxFormCitData,
      typeOfTaxFormOtherData: typeOfTaxFormOtherData,
      companySelectOptionsField: [],
      companyAccountSelectOptionsField: typeOfAccountData,
      companyTaxGroupOptionsField: typeOfTaxData,
      companyTaxFormSymbolOptionsField: null,
      companyCitOneTimeOrPermanentOptionsField: [
        { index: 0, name: "Jednorazowy" },
        { index: 1, name: "Cykliczny" },
      ],
      companySelectValueField: null,
      companyForm: {
        companyId: "",
        companyNameField: "",
        companyAddressField: "",
        companyTaxNumberField: "",
        companyAccountSelectValueField: null,
        companyTaxGroupValueField: null,
        companyTaxFormSymbolValueField: null,
        companyCitOneTimeOrPermanentValueField: null,
      },
    };
  },
  validations: {
    companyForm: {
      companyId: {
        required,
      },
      companyNameField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      companyAddressField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      companyAccountSelectValueField: {
        required,
      },
      companyTaxGroupValueField: {
        required,
      },
      companyTaxFormSymbolValueField: {
        required,
      },
      companyCitOneTimeOrPermanentValueField: {
        required,
      },
    },
  },
  created() {
    this.getCompanies();
    if (this.clonedData !== null) {
      let taxGroupArr = this.companyTaxGroupOptionsField.filter((obj) => {
        return (
          obj.name === this.clonedData.applicationSpecificData.taxGroupSymbol
        );
      });
      this.companyForm.companyTaxGroupValueField = taxGroupArr[0];

      this.populateAction(this.companyForm.companyTaxGroupValueField);

      let taxSymbolArr = this.companyTaxFormSymbolOptionsField.filter((obj) => {
        return (
          obj.name === this.clonedData.applicationSpecificData.taxFormSymbol
        );
      });
      this.companyForm.companyTaxFormSymbolValueField = taxSymbolArr[0];

      if (this.clonedData.applicationSpecificData.isRecurring === true) {
        this.companyForm.companyCitOneTimeOrPermanentValueField = {
          index: 1,
          name: "Cykliczny",
        };
      }
    }
  },
  methods: {
    validate() {
      this.$v.companyForm.$touch();
      this.$refs.InputTaxNumber.$v.$touch();
      var isValid =
        !this.$v.companyForm.$invalid && !this.$refs.InputTaxNumber.$v.$invalid;
      this.$emit("on-validate", this.$data.companyForm, isValid);
      return isValid;
    },
    dispatchAction(el) {
      this.companyId = el.companyId;
      this.companyForm.companyId = el.companyId;
      this.companyForm.companyNameField = el.name;
      this.companyForm.companyAddressField = el.address;
      this.companyForm.companyTaxNumberField = el.nip;
    },
    populateAction(actionName) {
      this.showTaxFormSelect = true;
      this.showCitOneTimeOrPermanentSelect = false;
      switch (actionName.name) {
      case "PIT":
        this.companyTaxFormSymbolOptionsField = this.typeOfTaxFormPitData;
        this.companyForm.companyCitOneTimeOrPermanentValueField = {
          index: 0,
          name: "Jednorazowy",
        };
        break;
      case "VAT":
        this.companyTaxFormSymbolOptionsField = this.typeOfTaxFormVatData;
        this.companyForm.companyCitOneTimeOrPermanentValueField = {
          index: 0,
          name: "Jednorazowy",
        };
        break;
      case "CIT":
        this.companyTaxFormSymbolOptionsField = this.typeOfTaxFormCitData;
        this.showCitOneTimeOrPermanentSelect = true;
        break;
      case "Inne":
        this.companyTaxFormSymbolOptionsField = this.typeOfTaxFormOtherData;
        this.companyForm.companyCitOneTimeOrPermanentValueField = {
          index: 0,
          name: "Jednorazowy",
        };
        break;
      }
      if (this.companyForm.companyTaxFormSymbolValueField) {
        this.companyForm.companyTaxFormSymbolValueField = [];
      }
    },
    companyLabel({ name, nip, address }) {
      return `${name} ${nip} ${address}`;
    },
    accountAndTaxLabel({ name, description }) {
      return `${name} ${description}`;
    },
    getCompanies() {
      this.inputLoading = true;
      ApplicantsCompanyRepository.getAll()
        .then((response) => {

          this.companySelectOptionsField = response.data.filter((obj) => {
            return obj.isActive === true;
          });

          if (this.clonedData !== null) {
            this.companySelectValueField = this.companySelectOptionsField.filter(
              (obj) => {
                return obj.companyId === this.clonedData.companyId;
              }
            );
            this.dispatchAction(this.companySelectValueField[0]);
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
    getBankTypes(companyId) {
      this.inputLoading = true;
      PaymentCompanyRepository.getBankTypes(companyId)
        .then((response) => {
          let options = [];
          response.data.forEach((val) => {
            options.push(
              typeOfAccountData.find((type) => type.index === val.index)
            );
          });
          this.companyAccountSelectOptionsField = options;
          this.companyForm.companyAccountSelectValueField = options.length > 0 ? options[0] : null;
          if (this.clonedData !== null) {
            let account = this.companyAccountSelectOptionsField.filter(
              (obj) => {
                return obj.name === this.clonedData.typeOfBankAccount;
              }
            );
            this.companyForm.companyAccountSelectValueField = account[0];
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
    getNipValue: function (params) {
      this.companyForm.companyTaxNumberField = params;
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.companyForm.companyAccountSelectValueField = null;
        this.getBankTypes(newVal);
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="companySelectValueField">Wybierz firmę</label>
        <multiselect id="companySelectValueField" v-model.trim="companySelectValueField" :options="companySelectOptionsField" @select="dispatchAction" :custom-label="companyLabel" track-by="nip" name="companySelectValueField" placeholder="Wybierz firmę z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
              <span class="option__small small text-dark">{{ props.option.address }}</span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
      </div>
      <div class="form-group">
        <label for="companyNameField">
          Nazwa firmy
          <em class="text-danger">*</em>
        </label>
        <input id="companyNameField" v-model.trim="companyForm.companyNameField" @input="$v.companyForm.companyNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.companyForm.companyNameField.$error, 'is-valid': !$v.companyForm.companyNameField.$invalid && !$v.companyForm.companyNameField.$error }" type="text" name="companyNameField" placeholder="Wpisz nazwę firmy" value required disabled />
        <div v-if="$v.companyForm.companyNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="companyAddressField">
          Adres firmy
          <em class="text-danger">*</em>
        </label>
        <input id="companyAddressField" v-model.trim="companyForm.companyAddressField" @input="$v.companyForm.companyAddressField.$touch()" class="form-control" :class="{ 'is-invalid': $v.companyForm.companyAddressField.$error, 'is-valid': !$v.companyForm.companyAddressField.$invalid && !$v.companyForm.companyAddressField.$error }" type="text" name="companyAddressField" placeholder="Wpisz adres firmy" value required disabled />
        <div v-if="$v.companyForm.companyAddressField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
      <InputTaxNumber @input-tax-number="getNipValue" :incomingValue="companyForm.companyTaxNumberField" :inputLabel="'NIP firmy'" ref="InputTaxNumber" />
      <div class="form-group" :class="{ 'is-invalid' : $v.companyForm.companyAccountSelectValueField.$error }">
        <label for="companyAccountSelectValueField">
          Wybierz typ rachunku
          <em class="text-danger">*</em>
        </label>
        <multiselect id="companyAccountSelectValueField" v-model.trim="companyForm.companyAccountSelectValueField" :options="companyAccountSelectOptionsField" :custom-label="accountAndTaxLabel" track-by="index" @input="$v.companyForm.companyAccountSelectValueField.$touch()" :class="{ 'is-invalid': $v.companyForm.companyAccountSelectValueField.$error, 'is-valid': !$v.companyForm.companyAccountSelectValueField.$invalid && !$v.companyForm.companyAccountSelectValueField.$error }" name="companyAccountSelectValueField" placeholder="Wybierz typ rachunku z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.description }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
              <span class="option__small small text-dark">{{ props.option.description }}</span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.companyForm.companyAccountSelectValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
      <div class="form-group" :class="{ 'is-invalid' : $v.companyForm.companyTaxGroupValueField.$error }">
        <label for="companyTaxGroupValueField">
          Wybierz typ podatku
          <em class="text-danger">*</em>
        </label>
        <multiselect id="companyTaxGroupValueField" v-model.trim="companyForm.companyTaxGroupValueField" :options="companyTaxGroupOptionsField" :custom-label="accountAndTaxLabel" :allow-empty="false" track-by="index" @input="populateAction" :class="{ 'is-invalid': $v.companyForm.companyTaxGroupValueField.$error, 'is-valid': !$v.companyForm.companyTaxGroupValueField.$invalid && !$v.companyForm.companyTaxGroupValueField.$error }" name="companyTaxGroupValueField" placeholder="Wybierz typ podatku z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
            </div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.companyForm.companyTaxGroupValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
      <div class="form-group" :class="{ 'is-invalid' : $v.companyForm.companyTaxFormSymbolValueField.$error }" v-if="showTaxFormSelect">
        <label for="companyTaxFormSymbolValueField">
          Wybierz typ formularza
          <em class="text-danger">*</em>
        </label>
        <multiselect id="companyTaxFormSymbolValueField" v-model.trim="companyForm.companyTaxFormSymbolValueField" :options="companyTaxFormSymbolOptionsField" :custom-label="accountAndTaxLabel" track-by="name" @input="$v.companyForm.companyTaxFormSymbolValueField.$touch()" @select="$v.companyForm.companyTaxFormSymbolValueField.$reset()" :class="{ 'is-invalid': $v.companyForm.companyTaxFormSymbolValueField.$error, 'is-valid': !$v.companyForm.companyTaxFormSymbolValueField.$invalid && !$v.companyForm.companyTaxFormSymbolValueField.$error }" name="companyTaxFormSymbolValueField" placeholder="Wybierz typ formularza z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
            </div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.companyForm.companyTaxFormSymbolValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
      <div class="form-group" :class="{ 'is-invalid' : $v.companyForm.companyCitOneTimeOrPermanentValueField.$error }" v-if="showCitOneTimeOrPermanentSelect">
        <label for="companyCitOneTimeOrPermanentValueField">
          Wybierz cykliczność przelewu
          <em class="text-danger">*</em>
        </label>
        <multiselect id="companyCitOneTimeOrPermanentValueField" v-model.trim="companyForm.companyCitOneTimeOrPermanentValueField" :options="companyCitOneTimeOrPermanentOptionsField" :preselect-first="true" :allow-empty="false" track-by="index" @input="$v.companyForm.companyCitOneTimeOrPermanentValueField.$touch()" :class="{ 'is-invalid': $v.companyForm.companyCitOneTimeOrPermanentValueField.$error, 'is-valid': !$v.companyForm.companyCitOneTimeOrPermanentValueField.$invalid && !$v.companyForm.companyCitOneTimeOrPermanentValueField.$error }" name="companyCitOneTimeOrPermanentValueField" placeholder="Wybierz cykliczność przelewu z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
            </div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.companyForm.companyCitOneTimeOrPermanentValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
    </div>
  </div>
</template>
