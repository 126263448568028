<script>
import Vue from "vue";
import Switches from "vue-switches";
import {
  required,
  maxLength,
  minLength,
  numeric,
} from "vuelidate/lib/validators";
// import InputTaxNumber from "@/components/form-elements/input-tax-number"
import AddressFormGroup from "@/components/form-elements/address-form-group";
import Repository from "@/app/repository/repository-factory";

const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

export default {
  name: "providerDataStep",
  props: {
    companyId: {
      type: String,
      required: true,
    },
    taxGroupSymbol: {
      type: Object,
      required: true,
    },
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    Switches,
    // InputTaxNumber
    AddressFormGroup,
  },
  data() {
    return {
      inputLoading: false,
      canChangeDataManually: false,
      disabledSwitch: 0,
      providerForm: {
        providerNameField: "",
        providerAddressField: "",
        providerAddressCity: "",
        providerAddressStreet: "",
        providerAddressHouseNo: "",
        providerAddressFlatNo: "",
        providerAddressPostalCode: "",
        providerAddressInternational: "",
        providerAccountField: "",
        // providerTaxNumberField: null,
        providerFormDataModified: true,
        // providerWithoutTaxNumber: false,
      },
    };
  },
  validations: {
    providerForm: {
      providerNameField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      // providerAddressField: {
      //   required,
      //   maxLength: maxLength(255),
      //   minLength: minLength(3),
      // },
      providerAccountField: {
        required,
        maxLength: maxLength(26),
        minLength: minLength(26),
        numeric,
      },
    },
  },
  created() {
    if (this.clonedData !== null) {
      let provider = {
        name: this.clonedData.providerName,
        // address: this.clonedData.providerAddress,
        international: this.clonedData.applicationSpecificData.taxAuthorityAddress.international,
        street: this.clonedData.applicationSpecificData.taxAuthorityAddress.street,
        houseNo: this.clonedData.applicationSpecificData.taxAuthorityAddress.houseNo,
        flatNo: this.clonedData.applicationSpecificData.taxAuthorityAddress.flatNo,
        postalCode: this.clonedData.applicationSpecificData.taxAuthorityAddress.postalCode,
        city: this.clonedData.applicationSpecificData.taxAuthorityAddress.city,

        // nip: this.clonedData.providerTaxNumber,
        // provWithoutTax: this.clonedData.providerWithoutTaxNumber == 1 ? true : false,
        bankAccount: this.clonedData.providerBankAccountNumber,
        modified: this.clonedData.changeDataManually,
      };
      this.dispatchAction(provider);
    }
  },
  methods: {
    validate() {
      this.$v.providerForm.$touch();
      // this.$refs.InputTaxNumber.$v.$touch();
      this.$refs.AddressFormGroup.$v.$touch();
      var isValid = !this.$v.providerForm.$invalid && !this.$refs.AddressFormGroup.$v.$invalid;
      // var isValid = !this.$v.providerForm.$invalid && !this.$refs.InputTaxNumber.$v.$invalid;
      this.$emit("on-validate", this.$data.providerForm, isValid);
      return isValid;
    },
    dispatchAction(el) {
      this.providerForm.providerNameField = el.name;
      // if (el.address) {
      //   this.providerForm.providerAddressField = el.address;
      // } else {
      //   this.providerForm.providerAddressField =
      //     el.street + " " + el.homeNumber + ", " + el.postCode + " " + el.city;
      // }
      this.providerForm.providerAddressCity = el.city;
      this.providerForm.providerAddressStreet = el.street;
      this.providerForm.providerAddressHouseNo = el.houseNo;
      this.providerForm.providerAddressFlatNo = el.flatNo;
      this.providerForm.providerAddressPostalCode = el.postalCode;
      this.providerForm.providerAddressInternational = el.international;

      // this.providerForm.providerTaxNumberField = el.nip;
      this.providerForm.providerAccountField = el.bankAccount;
      this.disabledSwitch = 0;
      // this.providerForm.providerWithoutTaxNumber = this.clonedData !== null ? el.provWithoutTax : false;
      this.providerForm.providerFormDataModified = this.clonedData !== null && el.modified == 1 ? true : false;
      this.canChangeDataManually = this.clonedData !== null && this.clonedData.applicationSpecificData.taxGroupSymbol === "Inne" &&  el.modified == 1 ? true : false;
    },
    onChangeEventHandler(event) {
      this.disabledSwitch = event ? 1 : 0;
      if (this.disabledSwitch === 0) {
        this.getCompanyData();
      }
    },
    getCompanyData() {
      this.inputLoading = true;
      PaymentCompanyRepository.get(this.companyId)
        .then((response) => {
          let company = response.data;
          this.providerForm.providerNameField = company.taxAuthorityName;
          // this.providerForm.providerAddressField = company.taxAuthorityAddress;
          this.providerForm.providerAddressCity = company.taxAddressCity;
          this.providerForm.providerAddressStreet = company.taxAddressStreet;
          this.providerForm.providerAddressHouseNo = company.taxAddressHouseNo;
          this.providerForm.providerAddressFlatNo = company.taxAddressFlatNo;
          this.providerForm.providerAddressPostalCode = company.taxAddressPostalCode;
          this.providerForm.providerAddressInternational = company.taxAddressInternational;

          // this.providerForm.providerTaxNumberField = company.taxAuthorityNip;
          this.providerForm.providerAccountField = company.taxAuthorityBankAccount;
          this.inputLoading = false;
        })
        .catch((error) => {
          this.inputLoading = false;
          console.log(error);
        });
    },
    // getNipValue: function(params) {
    //   this.providerForm.providerTaxNumberField = params;
    // },
    getProviderAddressCityFormValues: function (params) {
      this.providerForm.providerAddressCity = params;
    },
    getProviderAddressStreetFormValues: function (params) {
      this.providerForm.providerAddressStreet = params;
    },
    getProviderAddressHouseNoFormValues: function (params) {
      this.providerForm.providerAddressHouseNo = params;
    },
    getProviderAddressFlatNoFormValues: function (params) {
      this.providerForm.providerAddressFlatNo = params;
    },
    getProviderAddressPostalCodeFormValues: function (params) {
      this.providerForm.providerAddressPostalCode = params;
    },
    getProviderAddressInternationalFormValues: function (params) {
      this.providerForm.providerAddressInternational = params;
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        if (this.clonedData !== null && this.companyId == this.clonedData.companyId && this.clonedData.applicationSpecificData.taxGroupSymbol === "Inne") {
          console.log('cloning')
        } else {
          this.getCompanyData();
        }
      }
    },
    taxGroupSymbol: function (newVal, oldVal) {
      if (newVal.name === "Inne") {
        this.canChangeDataManually = true;
        this.providerForm.providerFormDataModified = true;
      } else {
        this.disabledSwitch = 0;
        this.canChangeDataManually = false;
        this.providerForm.providerFormDataModified = false;
        // this.providerForm.providerWithoutTaxNumber = false;
        this.getCompanyData();
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="providerNameField">
          Nazwa organu podatkowego
          <em class="text-danger">*</em>
        </label>
        <input id="providerNameField" v-model.trim="providerForm.providerNameField" @input="$v.providerForm.providerNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerNameField.$error, 'is-valid': !$v.providerForm.providerNameField.$invalid && !$v.providerForm.providerNameField.$error }" type="text" name="providerNameField" placeholder="Wpisz nazwę organu podatkowego" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>

      <AddressFormGroup
      @address-city-form-group="getProviderAddressCityFormValues"
      @address-street-form-group="getProviderAddressStreetFormValues"
      @address-house-no-form-group="getProviderAddressHouseNoFormValues"
      @address-flat-no-form-group="getProviderAddressFlatNoFormValues"
      @address-postal-code-form-group="getProviderAddressPostalCodeFormValues"
      @address-international-form-group="getProviderAddressInternationalFormValues"
      :incomingAddressCityValue="providerForm.providerAddressCity"
      :incomingAddressStreetValue="providerForm.providerAddressStreet"
      :incomingAddressHouseNoValue="providerForm.providerAddressHouseNo"
      :incomingAddressFlatNoValue="providerForm.providerAddressFlatNo"
      :incomingAddressPostalCodeValue="providerForm.providerAddressPostalCode"
      :incomingAddressInternationalValue="providerForm.providerAddressInternational"
      :disable="disabledSwitch"
      :withoutAIField="true"
      name="AddressFormGroup"
      ref="AddressFormGroup" />

      <!-- <div class="form-group">
        <label for="providerAddressField">
          Adres organu podatkowego
          <em class="text-danger">*</em>
        </label>
        <input id="providerAddressField" v-model.trim="providerForm.providerAddressField" @input="$v.providerForm.providerAddressField.$touch()" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerAddressField.$error, 'is-valid': !$v.providerForm.providerAddressField.$invalid && !$v.providerForm.providerAddressField.$error }" type="text" name="providerAddressField" placeholder="Wpisz adres organu podatkowego" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerAddressField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div> -->
      <!-- <InputTaxNumber
        @input-tax-number="getNipValue"
        :incomingValue="providerForm.providerTaxNumberField"
        :disable="disabledSwitch"
        :inputLabel="'NIP organu podatkowego'"
        :withoutTaxNumber="providerForm.providerFormDataModified && providerForm.providerWithoutTaxNumber"
        ref="InputTaxNumber"
      /> -->
      <!-- <div class="d-flex justify-content-start" v-if="providerForm.providerFormDataModified && canChangeDataManually">
        <switches class="mt-2" v-model="providerForm.providerWithoutTaxNumber" type-bold="false" :color="providerForm.providerWithoutTaxNumber == 1 ? 'success' : 'primary'"></switches>
        <label class="mt-1 ml-3"><span class="mr-2">Organ podatkowy bez numeru NIP:</span>
          <span v-if="providerForm.providerWithoutTaxNumber" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
          <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
        </label>
      </div> -->
      <div class="form-group">
        <label for="providerAccountField">
          {{ taxGroupSymbol.name == 'Inne' ? ' Numer rachunku organu podatkowego' : 'Dedykowany numer rachunku organu podatkowego' }}
          <em class="text-danger">*</em>
        </label>
        <input id="providerAccountField" v-model.trim="providerForm.providerAccountField" @input="$v.providerForm.providerAccountField.$touch()" v-mask="'##########################'" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerAccountField.$error, 'is-valid': !$v.providerForm.providerAccountField.$invalid && !$v.providerForm.providerAccountField.$error }" type="text" name="providerAccountField" placeholder="Wpisz numer rachunku organu podatkowego" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerAccountField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.minLength">Zbyt mała ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
        </div>
      </div>
      <div class="d-flex justify-content-start" v-if="canChangeDataManually">
        <switches class="mt-2" v-model="providerForm.providerFormDataModified" @input="onChangeEventHandler($event)" type-bold="false" :color="disabledSwitch == 1 ? 'success' : 'primary'"></switches>
        <label class="mt-1 ml-3"><span class="mr-2">Zmień dane ręcznie:</span>
          <span v-if="providerForm.providerFormDataModified" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
          <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
        </label>
      </div>
    </div>
  </div>
</template>
