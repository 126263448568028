<script>
import { appService } from "@/app/service/appService";
/**
 * sidebar info component
 */
export default {
  props: {
    finalModel: {
      type: Object,
      required: true,
    },
    loggedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFilter: null,
    };
  },
  methods: {},
  computed: {
    codeNumber() {
      return appService.getPeriodTypeNumber(this.finalModel)
    },
  },
};
</script>

<template>
  <div class="card-body">
    <h5>Wnioskujący</h5>
    <div class="media d-flex align-items-center">
      <div class="avatar-xs mr-2">
        <b-avatar variant="success" :text="loggedUser.firstName +' '+ loggedUser.lastName | nameLetters"></b-avatar>
      </div>
      <div class="media-body">
        <p class="m-0 p-0">{{ loggedUser.firstName }} {{ loggedUser.lastName }}</p>
        <small class="text-muted">{{ loggedUser.email }}</small>
      </div>
    </div>
    <template v-if="this.finalModel.transferApproverSelectField.email">
      <hr />
      <h5>Akceptujący</h5>
      <div class="media d-flex align-items-center">
        <div class="avatar-xs mr-2">
          <b-avatar variant="warning" :text="this.finalModel.transferApproverSelectField.firstName +' '+ this.finalModel.transferApproverSelectField.lastName | nameLetters"></b-avatar>
        </div>
        <div class="media-body">
          <p class="m-0 p-0">{{ this.finalModel.transferApproverSelectField.firstName +' '+ this.finalModel.transferApproverSelectField.lastName }}</p>
          <small class="text-muted">{{ this.finalModel.transferApproverSelectField.email }}</small>
        </div>
      </div>
    </template>
    <template v-if="this.finalModel.providerFormDataModified && finalModel.transferHigherApproverSelectField && finalModel.transferHigherApproverSelectField.email">
      <hr />
      <h5>Autoryzujący zmiany dostawcy</h5>
      <div class="media d-flex align-items-center">
        <div class="avatar-xs mr-2">
          <b-avatar variant="info" :text="this.finalModel.transferHigherApproverSelectField.firstName +' '+ this.finalModel.transferHigherApproverSelectField.lastName | nameLetters"></b-avatar>
        </div>
        <div class="media-body">
          <p class="m-0 p-0">{{ this.finalModel.transferHigherApproverSelectField.firstName +' '+ this.finalModel.transferHigherApproverSelectField.lastName }}</p>
          <small class="text-muted">{{ this.finalModel.transferHigherApproverSelectField.email }}</small>
        </div>
      </div>
    </template>
    <template v-if="this.finalModel.companyNameField">
      <hr />
      <h5 class="m-0 p-0">Dane firmy</h5>
      <br />
      <strong class="mr-1">Nazwa:</strong>
      <span v-if="this.finalModel.companyNameField">{{ this.finalModel.companyNameField }}</span>
      <br />
      <strong class="mr-1">Adres:</strong>
      <span v-if="this.finalModel.companyAddressField">{{ this.finalModel.companyAddressField }}</span>
      <br />
      <strong class="mr-1">NIP:</strong>
      <span v-if="this.finalModel.companyTaxNumberField">{{ this.finalModel.companyTaxNumberField }}</span>
      <br />
      <strong class="mr-1">Rachunek:</strong>
      <span v-if="this.finalModel.companyAccountSelectValueField">{{ this.finalModel.companyAccountSelectValueField.description }}</span>
      <br />
      <template v-if="this.finalModel.typeOfApplicationName == 'Przelew skarbowy'">
        <strong class="mr-1">Podatek:</strong>
        <span>{{ this.finalModel.companyTaxGroupValueField.name }}</span>
        <br />
        <strong class="mr-1">Formularz:</strong>
        <span>{{ this.finalModel.companyTaxFormSymbolValueField.name }}</span>
        <br />
        <strong class="mr-1">Typ:</strong>
        <span>{{ this.finalModel.companyCitOneTimeOrPermanentValueField.name }}</span>
        <br />
        <strong class="mr-1" v-if="codeNumber">Kod:</strong>
        <span>{{ codeNumber }}</span>
        <br />
      </template>
      <strong class="mr-1">Kwota przelewu {{ this.finalModel.typeOfApplicationName == 'Przelew skarbowy' ? '' : ' brutto'}}:</strong>
      <span>{{ this.finalModel.transferGrossAmountField | currencyFilter }}</span>
      <template v-if="this.finalModel.typeOfApplicationName == 'Przelew krajowy' && this.finalModel.companyTransferTypeValueField.typeOfTransfer == 'Split payment'">
        <br />
        <strong class="mr-1">Kwota vat:</strong>
        <span>{{ this.finalModel.transferVatAmountField | currencyFilter }}</span>
        <br />
        <strong class="mr-1">Numer faktury Vat:</strong>
        <span>{{ this.finalModel.transferInvoiceNumberField }}</span>
      </template>
      <br />
      <strong class="mr-1">Waluta:</strong>
      <span>{{ this.finalModel.transferCurrencySelectField ?  this.finalModel.transferCurrencySelectField.id : 'PLN' }}</span>
    </template>
  </div>
</template>