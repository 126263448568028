<script>
import { appService } from "@/app/service/appService";
/**
 * Application summary preview
 */
export default {
  props: {
    summaryData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFilter: null,
      formatAccNo: null,
    };
  },
  methods: {
    makeAddress(el) {
      if (el.providerAddressInternational == null || el.providerAddressInternational == '') {
        let flatNo = !el.providerAddressFlatNo || el.providerAddressFlatNo != ''  ? '' : '/'+ el.providerAddressFlatNo;
        return el.providerAddressStreet +' '+ el.providerAddressHouseNo + flatNo +', '+ el.providerAddressPostalCode +' '+ el.providerAddressCity;
      } else {
        return el.addressInternational;
      }
    },
  },
  computed: {
    codeNumber() {
      return appService.getPeriodTypeNumber(this.summaryData)
    },
  },
};
</script>

<template>
  <div class="row px-2 py-3 mx-0 mb-4" style="border: 1px solid #ccc">
    <div class="col-12 col-md-6 mb-4 mb-md-0">
      <address>
        <h5 class="mb-1">Dane firmy</h5>
        <br />
        <strong class="mr-1">Nazwa:</strong>
        {{ summaryData.companyNameField }}
        <br />
        <strong class="mr-1">Adres:</strong>
        {{ summaryData.companyAddressField }}
        <br />
        <strong class="mr-1">NIP:</strong>
        <span>{{ summaryData.companyTaxNumberField }}</span>
        <br />
        <strong class="mr-1">Rachunek:</strong>
        {{ summaryData.companyAccountSelectValueField.description }}
      </address>
    </div>
    <div class="col-12 col-md-6 text-md-right">
      <address>
        <h5 class="mb-1">
          <template v-if="summaryData.typeOfApplicationName == 'Zasilenie karty'">Dane karty</template>
          <template v-else-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">Organ podatkowy</template>
          <template v-else>Dane dostawcy</template>
        </h5>
        <br />
        <template v-if="summaryData.typeOfApplicationName == 'Zasilenie karty'">
          <strong class="mr-1">Nr karty:</strong>
          {{ summaryData.prepaidCardSelectValueField.cardNumber }}
          <br />
          <strong class="mr-1">Konto:</strong>
          {{ summaryData.prepaidCardSelectValueField.bankAccountNumber | formatAccNo }}
          <br />
          <strong class="mr-1">Właściciel:</strong>
          {{ summaryData.prepaidCardSelectValueField.nameOfCardholder }}
          <br />
          <strong class="mr-1">Identyfikator użytkownika karty:</strong>
          {{ summaryData.prepaidCardSelectValueField.cardholderNumber }}
          <br />
        </template>
        <template v-else>
          <strong class="mr-1">Nazwa:</strong>
          {{ summaryData.providerNameField }}
          <br />
          <strong class="mr-1">Adres:</strong>
          {{ makeAddress(summaryData) }}
          <!-- {{ summaryData.providerAddressField }} -->
          <br />
          <template v-if="summaryData.typeOfApplicationName != 'Przelew skarbowy' && summaryData.providerTaxNumberField">
          <strong class="mr-1">NIP:</strong>
          {{ summaryData.providerTaxNumberField ? summaryData.providerTaxNumberField : ''}}
          <br />
          </template>
          <strong class="mr-1">Rachunek:</strong>
          {{ summaryData.providerAccountField | formatAccNo }}
          <br />
          <template v-if="summaryData.typeOfApplicationName == 'Przelew zagraniczny'">
            <strong class="mr-1">Bank:</strong>
            <span>{{ summaryData.providerBankNameField }} / {{ summaryData.providerCountryBankValueField.code }}</span>
            <br />
            <strong class="mr-1">BIC / SWIFT:</strong>
            <span>{{ summaryData.providerBicSwiftField }}</span>
            <br/>
          </template>
        </template>
      </address>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <p class="lead my-4 text-center">
            <strong>Tytuł przelewu</strong>
            <br />
            {{ summaryData.transferTitleField }}
          </p>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="bg-soft-secondary">
                <tr>
                  <th>Data operacji</th>
                  <th v-if="summaryData.typeOfApplicationName == 'Przelew krajowy' && summaryData.companyTransferTypeValueField.typeOfTransfer == 'Split payment'">Numer FV</th>
                  <th>Rodzaj rachunku</th>
                  <th class="text-right">Kwota przelewu {{ summaryData.typeOfApplicationName == 'Przelew skarbowy' ? '' : 'brutto' }}</th>
                  <th class="text-right" v-if="summaryData.typeOfApplicationName == 'Przelew krajowy' && summaryData.companyTransferTypeValueField.typeOfTransfer == 'Split payment'">Kwota vat</th>
                  <th>Waluta</th>
                  <th v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">Symbol</th>
                  <th v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">Typ</th>
                  <th v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">Kod</th>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy' && summaryData.companyCitOneTimeOrPermanentValueField.index == 1"><strong>Zakończenie cyklu</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ summaryData.transferDateOfOperationField | formattedDate }}</td>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew krajowy' && summaryData.companyTransferTypeValueField.typeOfTransfer == 'Split payment'">{{ summaryData.transferInvoiceNumberField }}</td>
                  <td>{{ summaryData.companyAccountSelectValueField.name }}</td>
                  <td class="text-right">{{ summaryData.transferGrossAmountField | currencyFilter }}</td>
                  <td class="text-right" v-if="summaryData.typeOfApplicationName == 'Przelew krajowy' && summaryData.companyTransferTypeValueField.typeOfTransfer == 'Split payment'">{{ summaryData.transferVatAmountField | currencyFilter }}</td>
                  <td>{{ summaryData.transferCurrencySelectField ?  summaryData.transferCurrencySelectField.id : 'PLN' }}</td>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">{{ summaryData.companyTaxFormSymbolValueField.name }}</td>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">{{ summaryData.companyCitOneTimeOrPermanentValueField.name }}</td>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy'">{{ codeNumber }}</td>
                  <td v-if="summaryData.typeOfApplicationName == 'Przelew skarbowy' && summaryData.companyCitOneTimeOrPermanentValueField.index == 1">{{ summaryData.transferDateOfOperationEndField | formattedDate }}</td>
                </tr>
              </tbody>
            </table>
            <hr class="p-0 m-0" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-alert variant="info" class="mb-3" show fade v-if="summaryData.transferRequestForConfirmationField || summaryData.transferProviderCheckedWhiteListField">
        <template v-if="summaryData.transferRequestForConfirmationField"><i class="mdi mdi-information-outline mr-2"></i>Wnioskujący zlecił dołączenie potwierdzenia wykonania przelewu.<br v-if="summaryData.transferProviderCheckedWhiteListField" /></template>
        <template v-if="summaryData.transferProviderCheckedWhiteListField"><i class="mdi mdi-information-outline mr-2"></i>Dostawca został sprawdzony na białej liście.</template>
      </b-alert>
      <b-alert variant="success" class="mb-3" show fade v-if="summaryData.typeOfApplicationName == 'Przelew zagraniczny'">
        <i class="mdi mdi-information-outline mr-2"></i>Prowizje i opłaty po stronie <strong>{{ summaryData.transferFeesAndChargesSelectField.name }}</strong>.<br />
        <i class="mdi mdi-information-outline mr-2"></i><strong>{{ summaryData.transferTaxOpinionField ? "Podlega" : "Nie podlega" }}</strong> opodatkowaniu.
      </b-alert>
      <b-alert variant="info" class="mb-3" show fade v-if="summaryData.transferAttachmentsField.length > 0">
        <i class="mdi mdi-information-outline mr-2"></i>Załączniki przypisane do wniosku: {{ summaryData.transferAttachmentsField.length }}.
      </b-alert>
      <b-alert variant="success" class="mb-3" show fade v-if="summaryData.typeOfApplicationName == 'Zasilenie karty'">
        <i class="mdi mdi-information-outline mr-2"></i>Karta <strong>{{ summaryData.prepaidCardChecked ? "została" : "nie została" }}</strong> sprawdzona.
        <template v-if="summaryData.checkedPrepaidCardData">
          <template v-if="summaryData.checkedPrepaidCardData.isSettled">
          <br />
          <i class="mdi mdi-information-outline mr-2"></i>Karta <strong>jest</strong> rozliczona.
          </template>
          <template v-if="summaryData.checkedPrepaidCardData.balance">
          <br/>
          <i class="mdi mdi-information-outline mr-2"></i>Na karcie istnieją nierozliczone transakcje na kwotę {{ summaryData.checkedPrepaidCardData.balance }}
          </template>
        </template>
      </b-alert>
      <template v-if="summaryData.checkedPrepaidCardData">
        <b-alert variant="danger" class="mb-3" show fade v-if="!summaryData.checkedPrepaidCardData.isSettled">
          <i class="mdi mdi-shield-alert-outline mr-2"></i>Karta <strong>nie jest</strong> rozliczona.
        </b-alert>
      </template>
      <blockquote class="blockquote" v-if="summaryData.transferAdditionalInfoField">
        <p class="mb-0 small">{{ summaryData.transferAdditionalInfoField }}</p>
        <footer class="blockquote-footer text-muted" style="font-size:75%">Dodatkowe uwagi od <cite title="Source Title">{{ summaryData.applicantFirstNameField +' '+ summaryData.applicantLastNameField }}</cite></footer>
      </blockquote>
    </div>
  </div>
</template>