const typeOfTaxFormPitData = [
  {
    index: 1,
    name: "PIT",
    description: "",
  },
  {
    index: 2,
    name: "PIT4",
    description: "",
  },
  {
    index: 3,
    name: "PIT8AR",
    description: "",
  },
];

const typeOfTaxFormVatData = [
  {
    index: 1,
    name: "VAT",
    description: "",
  },
  {
    index: 2,
    name: "VAT7",
    description: "",
  },
  {
    index: 3,
    name: "VAT7D",
    description: "",
  },
  {
    index: 4,
    name: "VAT7K",
    description: "",
  },
];

const typeOfTaxFormCitData = [
  {
    index: 1,
    name: "CIT",
    description: "",
  },
  {
    index: 2,
    name: "CIT8",
    description: "",
  },
];

const typeOfTaxFormOtherData = [
  {
    index: 1,
    name: "AKCU",
    description: "",
  },
  {
    index: 2,
    name: "PCC",
    description: "",
  },
  {
    index: 3,
    name: "PCC1",
    description: "",
  },
  {
    index: 4,
    name: "PCC2",
    description: "",
  },
  {
    index: 5,
    name: "PCC3",
    description: "",
  },
  {
    index: 6,
    name: "SAD",
    description: "",
  },
  {
    index: 7,
    name: "CIT10Z",
    description: "",
  },
  {
    index: 8,
    name: "CIT6R",
    description: "",
  },
];

const typeOfTaxData = [
  {
    index: 1,
    name: "PIT",
  },
  {
    index: 2,
    name: "VAT",
  },
  {
    index: 3,
    name: "CIT",
  },
  {
    index: 4,
    name: "Inne",
  },
];

export { typeOfTaxFormPitData };
export { typeOfTaxFormVatData };
export { typeOfTaxFormCitData };
export { typeOfTaxFormOtherData };
export { typeOfTaxData };
